.stepper-wrapper {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.stepper-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.stepper-item::before {
  position: absolute;
  content: "";
  border-bottom: 2px solid #ccc;
  width: 100%;
  top: 20px;
  left: -50%;
  z-index: 2;
}

.stepper-item::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid #ccc;
  width: 100%;
  top: 20px;
  left: 50%;
  z-index: 2;
}

.stepper-item .step-counter {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #ccc;
  margin-bottom: 6px;
}

.stepper-item.active {
  font-weight: bold;
  color: #58b12c;
}

.stepper-item.completed .step-counter {
  background-color: #4bb543;
  color: white;
  font-weight: 600;
}

.label-tpr {
  border: dotted 1px #00800063;
  padding: 0.29em;
  border-left: solid 3px;
  border-right: solid 3px;
  font-weight: 500 !important;
  font-style: italic;
  background-color: #ebf4eb9e;
}

.stepper-item.completed::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid #4bb543;
  width: 100%;
  top: 20px;
  left: 50%;
  z-index: 3;
}

.stepper-item:first-child::before {
  content: none;
}
.stepper-item:last-child::after {
  content: none;
}

/**************************/

.progressbar {
  counter-reset: step;
}
.progressbar li {
  list-style-type: none;
  width: 25%;
  float: left;
  font-size: 12px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  color: #7d7d7d;
}
.progressbar li:before {
  width: 30px;
  height: 30px;
  content: counter(step);
  counter-increment: step;
  line-height: 30px;
  border: 2px solid #7d7d7d;
  display: block;
  text-align: center;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  background-color: white;
}
.progressbar li:after {
  width: 100%;
  height: 2px;
  content: "";
  position: absolute;
  background-color: #7d7d7d;
  top: 15px;
  left: -50%;
  z-index: -1;
}
.progressbar li:first-child:after {
  content: none;
}
.progressbar li.active {
  color: green;
}
.progressbar li.active:before {
  border-color: #55b776;
}
.progressbar li.active + li:after {
  background-color: #55b776;
}

.addbtn {
  /* padding: 0px 10px; */
  /* background-color: #5d982e52;
  border: solid 1px#609736; */
  /* font-weight: bolder; */
  /*font-size: 1.2em;*/
  /* border-radius: 6%; */
  
  background-color: #d9e8cd;
  padding: 4px 5px;
  text-decoration: none;
  /* font-weight: bold; */
  border-radius: 5px;
  border: solid 1px #7e7e82;
  cursor: pointer;
}

.delbtn {
  padding: 0px 10px;
}

.step-name {
  font-size: 11px;
}

.label-h5 {
  font-size: 13px;
  font-weight: 600 !important;
  color: #5d982e !important;
  margin-bottom: 13px;
}

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: bold;
}

.row {
  display: flex;
  flex-wrap: wrap !important;
  margin-right: calc(-0.5 * 0px);
  margin-left: calc(-0.5 * 20px);
}

.seleval {
  border: 1px solid #d7546d !important;
}

.btnprime {
   background-color: #34495e;
   border-color: #34495e;
   color: #ffff;
}

.btnprime:active:hover, .btnprime.active:hover, .open > .dropdown-toggle.btnprime:hover, .btnprime:active:focus, .btnprime.active:focus, .open > .dropdown-toggle.btnprime:focus, .btnprime:active.focus, .btnprime.active.focus, .open > .dropdown-toggle.btnprime.focus {
  color: #fff;
  background-color: #204d74;
  border-color: #122b40;
}

.btn:focus, .btn:active:focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn.active.focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.btnprime:active, .btnprime.active, .open .dropdown-toggle.btnprime {
  background-image: none;
}
.btnprime:hover, .btnprime:focus, .btnprime:active, .btnprime.active, .open .dropdown-toggle.btnprime {
  background-color: #3f5872;
  border-color: #3f5872;
  color: #fff;
}
.float-e-margins .btn {
  margin-bottom: 5px;
}

.disappeared { transition: opacity 1s; }
.appeared { visibility : hidden; opacity: 0 }

.addRemovebtn {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 0px;
}

.qualiaddbtn {
  border-top: dashed 1px green;
  margin-top: 13px;
  padding-top: 10px;
}