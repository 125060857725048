body::-webkit-scrollbar {
  width: 0.5em;
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

h2 {
  font-size: 20px;
  padding: 10px;
  border-bottom: none;
}

.input-group {
  position: relative;
  display: flex;
  align-items: stretch;
  width: 95%;
}
