body::-webkit-scrollbar {
    width: 0.5em;
  }
  
  body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  }
  
  body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
  }
  
  h2 {
    font-size: 20px;
    padding: 10px;
    border-bottom: none;
  }

  .ql-editor {
    min-height: 252px;
  }

  .ql-container.ql-snow {
      border:none !important;
    /* border: 1px solid #ccc; */
}

.ql-toolbar.ql-snow {
    border: 1px solid #ccc;
    box-sizing: border-box;
    font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    padding: 8px;
    background-color: #eef1f2;
}
  .ql-toolbar.ql-snow {
    border: 1px solid #ccc;
    box-sizing: border-box;
    font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    padding: 8px;
    background-color: #eef1f2 !important;
}
  
.checkbox {
    padding-left: 20px; }
.checkbox label {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    padding-left: 5px; }
.checkbox label::before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 17px;
    height: 17px;
    left: 0;
    margin-left: -20px;
    border: 1px solid #cccccc;
    border-radius: 3px;
    background-color: #fff;
    -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
    -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
    transition: border 0.15s ease-in-out, color 0.15s ease-in-out; }
.checkbox label::after {
    display: inline-block;
    position: absolute;
    width: 16px;
    height: 16px;
    left: 0;
    top: 0;
    margin-left: -20px;
    padding-left: 3px;
    padding-top: 1px;
    font-size: 11px;
    color: #555555; }
.checkbox input[type="checkbox"] {
    opacity: 0;
    z-index: 1; }
.checkbox input[type="checkbox"]:focus + label::before {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px; }
.checkbox input[type="checkbox"]:checked + label::after {
    font-family: 'FontAwesome';
    content: "\f00c"; }
.checkbox input[type="checkbox"]:disabled + label {
    opacity: 0.65; }
.checkbox input[type="checkbox"]:disabled + label::before {
    background-color: #eeeeee;
    cursor: not-allowed; }
.checkbox.checkbox-circle label::before {
    border-radius: 50%; }
.checkbox.checkbox-inline {
    margin-top: 0; }

.checkbox-primary input[type="checkbox"]:checked + label::before {
    background-color: #428bca;
    border-color: #428bca; }
.checkbox-primary input[type="checkbox"]:checked + label::after {
    color: #fff; }

.checkbox-danger input[type="checkbox"]:checked + label::before {
    background-color: #d9534f;
    border-color: #d9534f; }
.checkbox-danger input[type="checkbox"]:checked + label::after {
    color: #fff; }

.checkbox-info input[type="checkbox"]:checked + label::before {
    background-color: #5bc0de;
    border-color: #5bc0de; }
.checkbox-info input[type="checkbox"]:checked + label::after {
    color: #fff; }

.checkbox-warning input[type="checkbox"]:checked + label::before {
    background-color: #f0ad4e;
    border-color: #f0ad4e; }
.checkbox-warning input[type="checkbox"]:checked + label::after {
    color: #fff; }

.checkbox-success input[type="checkbox"]:checked + label::before {
    background-color: #5cb85c;
    border-color: #5cb85c; }
.checkbox-success input[type="checkbox"]:checked + label::after {
    color: #fff; }

.radio {
    padding-left: 20px; }
.radio label {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    padding-left: 5px; }
.radio label::before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 17px;
    height: 17px;
    left: 0;
    margin-left: -20px;
    border: 1px solid #cccccc;
    border-radius: 50%;
    background-color: #fff;
    -webkit-transition: border 0.15s ease-in-out;
    -o-transition: border 0.15s ease-in-out;
    transition: border 0.15s ease-in-out; }
.radio label::after {
    display: inline-block;
    position: absolute;
    content: " ";
    width: 11px;
    height: 11px;
    left: 3px;
    top: 3px;
    margin-left: -20px;
    border-radius: 50%;
    background-color: #555555;
    -webkit-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);
    -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
    -moz-transition: -moz-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
    -o-transition: -o-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
    transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33); }
.radio input[type="radio"] {
    opacity: 0;
    z-index: 1; }
.radio input[type="radio"]:focus + label::before {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px; }
.radio input[type="radio"]:checked + label::after {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1); }
.radio input[type="radio"]:disabled + label {
    opacity: 0.65; }
.radio input[type="radio"]:disabled + label::before {
    cursor: not-allowed; }
.radio.radio-inline {
    margin-top: 0; }

.radio-primary input[type="radio"] + label::after {
    background-color: #428bca; }
.radio-primary input[type="radio"]:checked + label::before {
    border-color: #428bca; }
.radio-primary input[type="radio"]:checked + label::after {
    background-color: #428bca; }

.radio-danger input[type="radio"] + label::after {
    background-color: #d9534f; }
.radio-danger input[type="radio"]:checked + label::before {
    border-color: #d9534f; }
.radio-danger input[type="radio"]:checked + label::after {
    background-color: #d9534f; }

.radio-info input[type="radio"] + label::after {
    background-color: #5bc0de; }
.radio-info input[type="radio"]:checked + label::before {
    border-color: #5bc0de; }
.radio-info input[type="radio"]:checked + label::after {
    background-color: #5bc0de; }

.radio-warning input[type="radio"] + label::after {
    background-color: #f0ad4e; }
.radio-warning input[type="radio"]:checked + label::before {
    border-color: #f0ad4e; }
.radio-warning input[type="radio"]:checked + label::after {
    background-color: #f0ad4e; }

.radio-success input[type="radio"] + label::after {
    background-color: #5cb85c; }
.radio-success input[type="radio"]:checked + label::before {
    border-color: #5cb85c; }
.radio-success input[type="radio"]:checked + label::after {
    background-color: #5cb85c; }

    .btn-primary:hover {
        color: rgb(103, 129, 119);
        background-color: #9fdd97 !important;
        border-color: #81a781 !important;
    }
.messagebody {
    margin: 15px 10px;
    border: solid 1px #e1e3e5;
    border-radius: 20px;
    padding: 20px;
    background-color: #f6f7f5;
}


nav-tabs > li > a {
    cursor: pointer;
    color: #5c9731;
    font-weight: 600;
}

/************loader**********/

.lds-facebook {
    display: inline-block;
    position: relative;
    width: 9px;
    height: 9px;
  }
  .lds-facebook span {
    display: inline-block;
    position: absolute;
    left: 4px;
    width: 10px;
    background: rgb(90, 197, 87);
    animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  }
  .lds-facebook span:nth-child(1) {
    left: 4px;
    animation-delay: -0.24s;
  }
  .lds-facebook span:nth-child(2) {
    left: 16px;
    animation-delay: -0.12s;
  }
  .lds-facebook span:nth-child(3) {
    left: 28px;
    animation-delay: 0;
  }
  @keyframes lds-facebook {
    0% {
      top: 0px;
      height: 24px;
    }
    50%, 100% {
      top: 0px;
      height: 12px;
    }
  }

/*************loader End*************8**/
table.apptable thead tr {background-color: #f6f9fa;}
table.apptable tr:nth-child(even) {background-color: #f6f9fa;}

table.apptable tr td {vertical-align: middle;}

.ql-snow.ql-toolbar button:hover, 
.ql-snow .ql-toolbar button:hover, 
.ql-snow.ql-toolbar button:focus, 
.ql-snow .ql-toolbar button:focus, 
.ql-snow.ql-toolbar button.ql-active, 
.ql-snow .ql-toolbar button.ql-active, 
.ql-snow.ql-toolbar .ql-picker-label:hover, 
.ql-snow .ql-toolbar .ql-picker-label:hover, 
.ql-snow.ql-toolbar .ql-picker-label.ql-active, 
.ql-snow .ql-toolbar .ql-picker-label.ql-active, 
.ql-snow.ql-toolbar .ql-picker-item:hover, .ql-snow 
.ql-toolbar .ql-picker-item:hover, .ql-snow.ql-toolbar 
.ql-picker-item.ql-selected, .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
    color: #5c9731;
}

p.trackingNotes {
    padding: 10px;
    border: solid 1px #aea5a53b;
    border-radius: 7px;
    background-color: #a19a9a0d;
}

.liner {
    display: flex;
    align-items: flex-start;
    text-align: left;
  }
  .liner:after {
    content: "";
    flex-grow: 1;
    height: 1px;
    background: #c4c4cb;/*#D66853;*/
    min-width: 20px;
    margin: auto;
  }
  .liner:after {
    margin-left: 20px;
  }

  .superLabels {
    border-radius: 5px;
    height: 18px;
    border: 0;
    padding: 0 10px;
    border: 0;
    background-color: #acacb5;/*#D66853;*/
  }

  .holder {
    display: block;
    color: #ffffff;
    text-transform: uppercase;
  }

  .tabs-left .panel-body {
     width: 100%; 
     margin-left:0px;
}

/* @media (min-width: 991px) {
    #notes {
        display: none;
        height: auto !important;
        visibility: visible;
    }
} */

.toggle-container {
    transition: height 0.35s ease-in-out;
    overflow: hidden;
  }
  .toggle-container:not(.active) {
    display: none;
  }

  .message::before {
    border-color: transparent;
    border-right-color: #eaeaea;
    border-width: 11px;
    margin-top: -11px;

    right: 100%;
    top: 20px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  .activetag {
    background: #e0efe0;
    border: 1px solid #b9dbc3;
    font-weight: 600;
    border-radius: 5px;
    /* padding: 10px 15px; */
    display: block;
  }

  .activetag span {
    color : #0d1705;
  }