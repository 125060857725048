body::-webkit-scrollbar {
  width: 0.5em !important;
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.col-xs-1,
.col-sm-1,
.col-md-1,
.col-lg-1,
.col-xs-2,
.col-sm-2,
.col-md-2,
.col-lg-2,
.col-xs-3,
.col-sm-3,
.col-md-3,
.col-lg-3,
.col-xs-4,
.col-sm-4,
.col-md-4,
.col-lg-4,
.col-xs-5,
.col-sm-5,
.col-md-5,
.col-lg-5,
.col-xs-6,
.col-sm-6,
.col-md-6,
.col-lg-6,
.col-xs-7,
.col-sm-7,
.col-md-7,
.col-lg-7,
.col-xs-8,
.col-sm-8,
.col-md-8,
.col-lg-8,
.col-xs-9,
.col-sm-9,
.col-md-9,
.col-lg-9,
.col-xs-10,
.col-sm-10,
.col-md-10,
.col-lg-10,
.col-xs-11,
.col-sm-11,
.col-md-11,
.col-lg-11,
.col-xs-12,
.col-sm-12,
.col-md-12,
.col-lg-12 {
  position: relative;
  min-height: 1px !important;
  padding-right: 15px !important;
  padding-left: 15px !important;
}

h2 {
  font-size: 20px;
  padding: 10px;
  border-bottom: none;
}

#logo {
  float: left;
  width: 180px;
  background-color: #34495e;
  padding: 9px 10px 18px 18px;
  height: 56px;
  text-align: center;
}

#logo.light-version span {
  font-weight: 600;
  /*color: #34495e;*/
  color: #67874f;
  font-size: 26px;
}

.footer-p {
  margin: 0;
}

#menu {
  width: 180px;
  bottom: 0;
  float: left;
  left: 0;
  position: absolute;
  top: 62px;
  transition: margin 0.4s ease 0s;
  /*border-right: solid 1px #808084;*/
}

.hpanel .panel-body {
  border: 0;
  box-shadow: 0 1px 10px rgb(0 0 0 / 5%), 0 1px 2px rgb(0 0 0 / 10%);
}

#header {
  box-shadow: 0 1px 10px rgb(0 0 0 / 5%), 0 1px 2px rgb(0 0 0 / 10%);
  /*height: 55px;*/
  border: 0;
  z-index: 10;
}

.nav-tabs {
  border-bottom: 1px solid #eaeaea;
}

.color-line {
  background: #f7f9fa;
  height: 6px;
  background-image: -webkit-linear-gradient(
    left,
    #34495e,
    #34495e 25%,
    #9b59b6 25%,
    #9b59b6 35%,
    #3498db 35%,
    #3498db 45%,
    #62cb31 45%,
    #62cb31 55%,
    #ffb606 55%,
    #ffb606 65%,
    #e67e22 65%,
    #e67e22 75%,
    #e74c3c 85%,
    #e74c3c 85%,
    #c0392b 85%,
    #c0392b 100%
  );
  background-image: -moz-linear-gradient(
    left,
    #34495e,
    #34495e 25%,
    #9b59b6 25%,
    #9b59b6 35%,
    #3498db 35%,
    #3498db 45%,
    #62cb31 45%,
    #62cb31 55%,
    #ffb606 55%,
    #ffb606 65%,
    #e67e22 65%,
    #e67e22 75%,
    #e74c3c 85%,
    #e74c3c 85%,
    #c0392b 85%,
    #c0392b 100%
  );
  background-image: -ms-linear-gradient(
    left,
    #34495e,
    #34495e 25%,
    #9b59b6 25%,
    #9b59b6 35%,
    #3498db 35%,
    #3498db 45%,
    #62cb31 45%,
    #62cb31 55%,
    #ffb606 55%,
    #ffb606 65%,
    #e67e22 65%,
    #e67e22 75%,
    #e74c3c 85%,
    #e74c3c 85%,
    #c0392b 85%,
    #c0392b 100%
  );
  background-image: linear-gradient(
    to right,
    #89898d,
    #707077 25%,
    #34495e 25%,
    #337ab7 35%,
    #3498db 35%,
    #3498db 45%,
    #62cb31 45%,
    #5d9732 55%,
    #5d9732 55%,
    #73726e 65%,
    #62cb31 65%,
    #5d9732 75%,
    #255b7b 85%,
    #3ce771 85%,
    #8f8b8b 85%,
    #555454 100%
  );
  background-size: 100% 6px;
  background-position: 50% 100%;
  background-repeat: no-repeat;
}

#logo.light-version {
  background-color: #eef1f2;
  border-bottom: 1px solid #cfd2d3;
  text-align: center;
}

.hpanel > .panel-footer,
.hpanel > .panel-section {
  color: inherit;
  border: 1px solid #e0dfdf;
  border-top: 0;
  font-size: 90%;
  background: #f7f9fa;
  padding: 10px 15px;
}

.border-right {
  border-right: 1px solid #e4e4e5;
}

.alert-success {
  color: #c0d3e0;
  background-color: #34495e;
  border-color: #c6d9e9;
}

.hbreadcrumb > .active {
  color: #62cb31;
  font-weight: 600;
}

.nav-tabs > li > a {
  cursor: pointer;
  color: #62cb31;
  font-weight: 600;
}

/* .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
  color: #858589;
  text-decoration: none;
} */

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
  color: #848487;
  cursor: default;
  background-color: #fff;
  border: 1px solid #ddd;
  border-bottom-color: transparent;
}

.radio-inline,
.checkbox-inline {
  position: relative;
  display: inline-block;
  padding-left: 20px;
  margin-bottom: 0;
  font-weight: normal;
  vertical-align: baseline;
  cursor: pointer;
}
/* Button Primary*/
.btn-primary:hover {
  color: #fff;
  background-color: #93939b;
  border-color: #6f6f79;
}

.btn-check:active + .btn-primary,
.btn-check:checked + .btn-primary,
.btn-primary.active,
.btn-primary:active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #717177;
  border-color: #717177;
}

.btn-primary {
  color: #fff;
  background-color: #5d982e;
  border-color: #5d982e;
}

.btn-primary {
  -webkit-box-shadow: 0 2px 6px 0 rgb(81 86 190 / 50%);
  box-shadow: 0 2px 6px 0 rgb(116 120 141 / 88%);
}

.btn-check:focus + .btn-primary,
.btn-primary:focus {
  color: #fff;
  background-color: #4b6b4f;
  border-color: #4b6b4f;
  -webkit-box-shadow: 0 0 0 0.15rem rgb(107 111 200 / 50%);
  box-shadow: 0 0 0 0.15rem rgb(126 200 107 / 50%);
}

/* Button Custom*/

.btn-custom:hover {
  color: #fff;
  background-color: #78787e;
  border-color: #3f4441;
}

.btn-check:active + .btn-custom,
.btn-check:checked + .btn-custom,
.btn-custom.active,
.btn-custom:active,
.show > .btn-custom.dropdown-toggle {
  color: #fff;
  background-color: #06b331;
  border-color: #3c6b4e;
}

.btn-custom {
  color: #fff;
  background-color: #93939b;
  border-color: #6f6f79;
}

.btn-custom {
  -webkit-box-shadow: 0 2px 6px 0 rgb(81 86 190 / 50%);
  box-shadow: 0 2px 6px 0 rgb(116 120 141 / 88%);
}

.btn-check:focus + .btn-custom,
.btn-custom:focus {
  color: #fff;
  background-color: #4b6b4f;
  border-color: #4b6b4f;
  -webkit-box-shadow: 0 0 0 0.15rem rgb(107 111 200 / 50%);
  box-shadow: 0 0 0 0.15rem rgb(126 200 107 / 50%);
}

.pscreen-header {
  background-color: #5d982e !important;
  border-color: #5d982e !important;
  /* background-color: #808084 !important;
  border-color: #808084 !important; */
}

.hpanel.panel-collapse > .panel-heading {
  background: transparent;
  border-color: #eaeaea;
  border: 1px solid #eaeaea;
  padding: 10px 10px;
  border-radius: 2px;
}

.naver-link {
  font-size: 14px;
  float: left;
  border-left: 1px solid #eaeaea;
  cursor: pointer;
}

.navbar.navbar-static-top a,
.nav.navbar-nav li a i {
  color: #5e9733;
}

.navbar.navbar-static-top a,
.nav.navbar-nav li a {
  color: #808084;
}
.dtab-active {
  background: #5d982e !important;
  transition: all 0.5s ease;
}

.dtab {
  display: inline-block;
  height: 7px;
  border-radius: 3.5px;
  background: #a1a1a1;
  transition: all 0.5s ease;
  box-shadow: inset 0 3px 5px rgb(0 0 0 / 13%);
}

.other {
  height: 0px !important;
}

.collapsin {
  /* display: block; */
  /* max-height: 100% !important; */
  max-height: 2800px !important;
}

.txtareahide {
  /* display: none; */
  max-height: 0;
  /* transition: max-height 5s ease-out;
  -webkit-transition: max-height 5s ease-out; */
  -webkit-transition: max-height 1s;
  -moz-transition: max-height 1s;
  -ms-transition: max-height 1s;
  -o-transition: max-height 1s;
  transition: max-height 0.75s ease-out;
  overflow: hidden;
}

.collapsinw {
  /* display: block; */
  max-width: 500px !important;
  max-height: 80px !important;
}

.whide {
  /* display: none; */
  max-width: 0;
  max-height: 0;
  /* transition: max-height 5s ease-out;
  -webkit-transition: max-height 5s ease-out; */
  -webkit-transition: max-width 1s;
  -moz-transition: max-width 1s;
  -ms-transition: max-width 1s;
  -o-transition: max-width 1s;
  transition: max-width 0.25s ease-out;

  -webkit-transition: max-height 1s;
  -moz-transition: max-height 1s;
  -ms-transition: max-height 1s;
  -o-transition: max-height 1s;
  transition: max-height 0.25s ease-out;

  overflow: hidden;
}

.radio label,
.checkbox label {
  padding-left: 0px;
}

.wizardbtn {
  margin: 0px 10px;
  width: 40px;
  height: 40px;
  text-align: center;
  border-radius: 50%;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #89898d;
}

.wizardpane {
  margin-top: -10px;
  margin-right: 5px;
  margin-left: 5px;
  border-top: dashed 1px#5d982e;
  /* border-top-right-radius: 10%;
  border-top-left-radius: 10%; */
}

.-o {
  border-bottom: dashed 1px green;
  margin-bottom: 5px;
  /* border-top-right-radius: 10%;
  border-top-left-radius: 10%; */
}

.list-group-item-heading {
  margin-top: 0;
}

.label-clr {
  font-weight: 700 !important;
  color: #5d982e !important;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1.3rem;
  font-weight: 600;
  line-height: 1.8;
  color: #5d9732;
  background-color: #fff;
  background-image: url(images/download.svg);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.wizard-nav-btn {
  border-color: #cbcbcb;
  margin-right: 5px;
  padding: 3px 12px;
  box-shadow: inset 0 1px 5px rgb(0 0 0 / 7%);
}

.col-bb {
  border-bottom: dashed 1px green;
  margin-bottom: 5px;
}

.rowbuttom {
  border-bottom: solid 2px green;
  margin-bottom: 5px;
}

.txtareabb {
  border: solid 1px #5d9732 !important;
  border-radius: 4px !important;
}

.mt-md-5 {
  margin-top: 0px !important;
}

#side-menu li.active {
  background: #fff;
  border-right: solid medium green;
}

#side-menu li.active a {
  color: #808084;
  font-weight: 700;
}

#side-menu li a {
  color: #939d8c;
  text-transform: uppercase;
  font-weight: 500;
  transition: color 0.25s;
  transition: text-transform 0.25s;
}

.form-control {
  border-color: #5d97327a !important;
}

.notifyLink {
  text-align: center !important;
}