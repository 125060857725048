/* #side-menun li.active {
    background: #f6f9fa;
    border-right: solid 2px #61cb31;
} */
#side-menun li.active {
    background: #fff;
    border-right: solid 2px #61cb31;
}
#side-menun li:first-child {
    border-top: 1px solid #f0f3f6;
}
#side-menun li {
    border-bottom: 1px solid #f0f3f6;
}

#side-menum {
   background-color: #f8f9fb;
}

.nav > li > a:hover, .navn > li > a:focus {
    text-decoration: none;
    font-weight: bold;
    background-color: #dfe4e9;
}

.nav > li > a:hover, .navn > li > a:focus {
    text-decoration: none;
    background-color: #e3e7eb;
}
.nav > li > a {
    position: relative;
    display: block;
    padding: 10px 15px;
}

a:hover, a:focus {
    color: #34495e;
    text-decoration: none;
}

.label-menu-corner .label {
    font-size: 10px;
    line-height: 12px;
    padding: 2px 5px;
    position: absolute;
    right: 12px;
    top: 6px;
}

.header-link-admin {
    padding: 18px 26px 21px 26px;
    font-size: 14px;
    float: left;
    border-right: 1px solid #eaeaea;
    border-left: 1px solid #eaeaea;
    margin-right: 15px;
    background: #f7f9fa;
    cursor: pointer;
}

hr.style14 { 
    border: 0; 
    height: 1px; 
    background-image: -webkit-linear-gradient(left, #f0f0f0, #8c8b8b, #f0f0f0);
    background-image: -moz-linear-gradient(left, #f0f0f0, #8c8b8b, #f0f0f0);
    background-image: -ms-linear-gradient(left, #f0f0f0, #8c8b8b, #f0f0f0);
    background-image: -o-linear-gradient(left, #f0f0f0, #8c8b8b, #f0f0f0); 
  }

.slash-2 {
    /* height: 1px;
    color: #eaeaea;
    background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%223px%22%20height%3D%223px%22%20viewBox%3D%220%200%203%203%22%20fill%3D%22darkmagenta%22%3E%3Cpolygon%20points%3D%220%2C0.5%200%2C1.5%201.5%2C3%202.5%2C3%22/%3E%3Cpolygon%20points%3D%222.5%2C0%201.5%2C0%203%2C1.5%203%2C0.5%22/%3E%3C/svg%3E);
    background-size: 3px 3px;
    width: 100%; */
    border-top: 3px double #bddcae; /*#dddbdb;*/
    margin-top: 5px;
    margin-bottom: 5px;
}

.slash-3{
    border-top: 1px dotted #61cb31;
    border-bottom: 1px dotted #fff;
    margin-top: 3px;
    margin-bottom: 6px;
}

.dd-handle:hover {
    background: #f0f0f0;
    cursor: pointer;
    font-weight: normal; 
}


/********************** HR *****************************/
.hr-text {
    line-height: 1em;
    position: relative;
    outline: 0;
    border: 0;
    color: black;
    text-align: center;
    height: 1.5em;
    opacity: 0.8;
    font-weight: 900;
}
hr {
    box-sizing: content-box;
    height: 0;
}
button, hr, input {
    overflow: visible;
}

.hr-text:before {
    content: "";
    background: linear-gradient(to right, transparent, #818078, transparent);
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
}

.hr-text:after {
    content: attr(data-content);
    position: relative;
    display: inline-block;
    color: black;
    padding: 0 0.5em;
    line-height: 1.5em;
    color: #72766e;
    background-color: #ffffff;
    border: solid 1px #c5cacf;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    background-color: #0000009e;
}

.modal-sm {
    width: 500px;
}

.row {
    display: flex;
    flex-wrap: wrap !important;
    margin-right: calc(-0.5 * 20px);
    margin-left: calc(-0.5 * 20px);
}

table.apptable thead tr {background-color: #f6f9fa;}
table.apptable tr:nth-child(even) {background-color: #f6f9fa;}

table.apptable tr td {vertical-align: middle;}

.modal-85w {
    width:85%;
}

.reg-btn {
    width: 20% !important;
}

.showprescreenform{
    transform: translateX(0%);
    opacity:1;
    height:auto;
    transition: all 0.3s ease-in-out;
}

.hideprescreenform {
    opacity:0;
    height:0;
    transform: translateX(100%);
}

.showverifysmsform{
    transform: translateX(0%);
    opacity:1;
    height:auto;
    transition: all 0.3s ease-in-out;
}

.hideverifysmsform {
    opacity:0;
    height:0;
    transform: translateX(100%);
}

.showregform{
   opacity:1;
   height:auto;
}

.hideregform {
    transform: translateX(-100%);
    opacity: 0;
    height:0;
    transition: all 0.3s ease-in-out;
    /* transition: transform 0.3s ease-in-out; */
}

/* .select-panel {
    background-color: red;
} */
.panel-content {
    border: solid 1px red;
}

.css-1uvwlwy {
    margin: 0;
    padding-left: 0;
}

.rowMargin {
    margin : 15px 10px !important;
}

.rowMain {
    margin-right: -15px;
    margin-left: -15px;
}

.invoiceinputs {
    font-size: 25px;
    font-weight: bold;
    text-align: center;
    background-color: #ecf1ec;
}

.invoiceRowpaid {
    border: solid 2px #30a530;
    margin: 5px;
    border-radius: 5px;
    align-items: baseline;
}

.invoiceRow {
    border: dashed 1px #a7b993;/* #ce3b3b; */
    margin: 5px;
    border-radius: 5px;
    align-items: baseline;
}

.totallpaid {
    font-size: 15px;
    color: #5db85d;
    font-weight: bold;
}

.totalls {
    font-size: 15px;
    color: #8a8d8a;
    font-weight: bold;
}

.outstanding {
    font-size: 15px;
    color: #d42806;
    font-weight: bold;
}

.totallsmetric {
    border-bottom: solid 1px #d2cccc;
    border-right: dotted 1px #d2cccc;
}

.totallsmetricval {
    border-bottom: solid 1px #d2cccc;
    border-left: dotted 1px #d2cccc;
}

.activetag {
    background: #f0f5ed7d; /* #e0efe0; */
    border: 1px solid #b9dbc3;
    font-weight: 600;
    border-radius: 5px;
    /* padding: 10px 15px; */
    display: block;
  }
  
.activetag span {
    color : #0d1705;
  }

.showmenuitem {
    display: none;
}