.label-clr {
  font-weight: 600 !important;
  color: #5d982e !important;
}

.label-h5 {
  font-size: 13px;
  font-weight: 600 !important;
  color: #5d982e !important;
  margin-bottom: 13px;
}

.wizardbtn {
  margin: 0px 10px;
  width: 40px;
  height: 40px;
  text-align: center;
  border-radius: 50%;
  border-color: #ced8cb;
}

span.errorValidate {
	color: #bf1650;
}

span.errorValidate::before {
	display: inline;
    content: "⚠ ";
}

p.errorValidate {
	color: #bf1650;
}

p.errorValidate::before {
	display: inline;
    content: "⚠ ";
}

.seleval {
  border: 1px solid #d7546d !important;
}

.row {
    --bs-gutter-x: 20px;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-left: calc(-0.5 * var(--bs-gutter-x));
}

.form-group {
    margin-bottom: 7px;
}

.apptable {
  margin-bottom: 0;
}