.dropdown-menul {
    font-size: 13px;
    border-radius: 3px;
}

.dropdown-menul {
    position: absolute;
    /* top: 100%; */
    /* left: 0; */
    z-index: 1000;
    /* display: none; */
    float: left;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 14px;
    text-align: left;
    list-style: none;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    -webkit-box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
    box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
}

.dropdown-menul {
    margin-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.dropdown-menul {
    right: 0;
    left: auto;
}

.dropdown-menul {
    display: block;
}

.animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}


.TypeAheadDropDown{
    width: 100%;
    border: 1px solid gray;
    box-shadow: 0 0 1px rgba(0,0,0,0.1), 0 2px 4px 1px rgba(0,0,0, .18);
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.73);
   }
    
   .TypeAheadDropDown input{
    width: 100%;
    border: none;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.73);
    padding: 10px 5px ;
    box-sizing: border-box;
    outline: none;
    
   }
   .TypeAheadDropDown ul::before{
    content: '';
   }
    
   .TypeAheadDropDown ul{
   list-style-type: none;
   text-align: left;
   margin: 0;
   padding: 0;
   border-top: 1px solid gray;
   }
    
   .TypeAheadDropDown li{
    padding: 10px 5px ;
    cursor: pointer;
   }
    
   .TypeAheadDropDown li:hover{
    background: lightgray;
    text-decoration: underline;
   }

   .options {
    position: absolute;
    display: block;
    max-height: 300px;
    overflow: auto;
    will-change: transform;
    width: 96%;
    top: -13px;
    left: 13px;
    z-index: 1999;
    background-color: #f6f9fa;
    transform: translate3d(0px, 38px, 0px);

    float: left;
    min-width: 10rem;
    padding: 0.6rem;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;

    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: 0.25rem;
   }

   .option-active {
       transition: all 0.2s ease-out;
   }

   .option-active:hover {
    color: #16181b;
    text-decoration: none;
    background-color: #d1e6ca;
    cursor: pointer;
    font-size: 14px;
   }